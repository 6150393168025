.CharImg{
	align-items: center;
	vertical-align: center;
	display: flex;
	justify-content: center;
	align-content: center;
	margin: 0;
	/* border-radius: 5%; */
	max-width: 100%;
}

.CharName{
	display:block;
	margin:auto;
	justify-content: center;
	align-content: center;
	margin-top: 5px;
	margin-bottom: 5px;
}

.CharCard{
	max-width: 6vw;
	min-width: 100px;
	margin: 0;
	margin-top: 10px;
	color: white;
	font-size: 1.2vw;
}

.CharacterBar{
	padding: 0 !important;

	margin:0;
	max-height: 80vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.searchBarContainer{
	width: 100%;
	/* max-width:100px; */
}
.QueryContainer{
	max-height: 100vh;
	max-width: 33vw !important;
	padding: 0 !important;
	margin-bottom: 20px;
}

.CharacterBarRow{
	justify-content: center !important;
	max-width: 500px;
}
